import xhr from './xhr/';
/**
 * 预警管理相关的 API
 */
class ServicesService {
	// 增加服务
	addServices(data) {
		return xhr({
			method: 'post',
			url: '/services/add',
			params: data,
		});
	}

	// 服务详情
	detailServices(data) {
		return xhr({
			method: 'get',
			url: '/services/detail',
			params: data,
		});
	}

	// 促进服务搜索首页
	indexServices(data) {
		return xhr({
			method: 'get',
			url: '/services/index',
			params: data,
		});
	}

	// 服务搜索
	listServices(data) {
		return xhr({
			method: 'get',
			url: '/services/list',
			params: data,
		});
	}

	// 服务搜索
	donePageServices(data) {
		return xhr({
			method: 'get',
			url: '/services/done_page',
			params: data,
		});
	}

	// 删除服务
	deleteServices(data) {
		return xhr({
			method: 'post',
			url: '/services/delete',
			params: data,
		});
	}

	// 美奇血糖列表
	MQBloodSugarList(data) {
		return xhr({
			method: 'post',
			url: '/meiqi/monitorlist',
			params: data,
		});
	}

	// 美奇血糖分析
	MQBloodSugarAnalysis(data) {
		return xhr({
			method: 'post',
			url: '/meiqi/jrxtfx',
			params: data,
		});
	}

	// 美奇血糖概述
	MQBloodSugarSummary(data) {
		return xhr({
			method: 'post',
			url: '/meiqi/persongeneral',
			params: data,
		});
	}

	// 美奇血糖曲线
	MQBloodSugarCurve(data) {
		return xhr({
			method: 'post',
			url: '/meiqi/xtqx',
			params: data,
		});
	}

	// 美奇单日报告时间
	MQReportTimes(data) {
		return xhr({
			method: 'post',
			url: '/meiqi/reportpage',
			params: data,
		});
	}

	// 美奇单日报告
	MQReportSingleDay(data) {
		return xhr({
			method: 'post',
			url: '/meiqi/reportday',
			params: data,
		});
	}

	// 美奇七日报告
	MQReportSevenDays(data) {
		return xhr({
			method: 'post',
			url: '/meiqi/reportsevenday',
			params: data,
		});
	}

	// 美奇七日报告 - 行为
	MQReportSevenDaysBehavior(data) {
		return xhr({
			method: 'post',
			url: '/meiqi/qtxwpj',
			params: data,
		});
	}

	// 美奇详情曲线
	MQDetailCharts(data) {
		return xhr({
			method: 'post',
			url: '/meiqi/qrxtqx',
			params: data,
		});
	}

	// 美奇七日报告 - 食物耐受性
	MQFoodTolerability(data) {
		return xhr({
			method: 'post',
			url: '/meiqi/xwnsx',
			params: data,
		});
	}

	// 美奇 15 日报告
	MQReportFifteenDays(data) {
		return xhr({
			method: 'post',
			url: '/meiqi/swrbg',
			params: data,
		});
	}

	// 美奇报告人
	getMQReportUsers(data) {
		return xhr({
			method: 'post',
			url: '/meiqi/monitoruser',
			params: data,
		});
	}

	// 美奇报告 - 结束监测
	endMQMonitor(data) {
		return xhr({
			method: 'post',
			url: '/meiqi/qzjs',
			params: data,
		});
	}
}

// 实例化后导出，全局单例
export default new ServicesService();
